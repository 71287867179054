@tailwind base;
@tailwind components;
@tailwind utilities;

/*
py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md
hover:bg-blue-700 focus:outline-none focus:ring-2
focus:ring-blue-400 focus:ring-opacity-75;
*/

@layer components {
    .h1 {
        @apply text-3xl mb-4 font-black text-sky-900;
    }

    .h2 {
        @apply text-2xl mb-4 font-bold text-sky-900;
    }

    .h3 {
        @apply text-xl mb-4 font-bold text-sky-900;
    }

    .h4 {
        @apply text-lg mb-4 font-semibold text-sky-900;
    }

    .p {
        @apply mb-4;
    }

    .ol {
        @apply ml-2 mb-4 list-decimal pl-4;
    }

    .ul {
        @apply mb-4 list-disc pl-4;
    }

    .dl {
        @apply list-decimal pl-4;
    }

    .dt {
        @apply font-bold;
    }

    dd {
        @apply mb-4 border-b border-slate-300 border-dotted pb-4 pl-4;
    }
}
